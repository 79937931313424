<!-- Title Bar -->
<div class="title-bar">
    <div class="title">
        <h1 translate title="{{ 'SCTE_35' | translate }}">SCTE_35</h1>
    </div>
    <div class="actions"></div>
</div>
<!-- List Panel -->
<div class="event-panel">
    <div class="event-container">
        <div class="event-filters">
            <button
                type="button"
                class="btn btn-outline-primary mb-3"
                (click)="showFilter = !showFilter"
                title="{{ 'SHOW_FILTERS' | translate }}"
                [ngClass]="{ showFilter: !showFilter }"
            >
                <fa-icon icon="chevron-down" size="sm" class="me-1" *ngIf="showFilter"></fa-icon>
                <span *ngIf="showFilter">{{ "SHOW_FILTERS" | translate }}</span>
                <fa-icon icon="chevron-up" size="sm" class="me-1" *ngIf="!showFilter"></fa-icon>
                <span *ngIf="!showFilter">{{ "HIDE_FILTERS" | translate }}</span>
            </button>
            <form id="form" #form="ngForm">
                <div class="filter-container" *ngIf="!showFilter">
                    <fa-icon icon="times" size="lg" class="d-lg-none closeFilter" (click)="showFilter = !showFilter" *ngIf="!showFilter"></fa-icon>
                    <h3 class="sr-only" title="{{ 'FILTERS' | translate }}">{{ "FILTERS" | translate }}</h3>
                    <!-- Search -->
                    <div class="form-group">
                        <label for="msgFilter">{{ "MESSAGE" | translate }} {{ "SEARCH" | translate }}</label>
                        <input
                            class="form-control minw-200px"
                            type="text"
                            id="msgFilter"
                            name="msgFilter"
                            [(ngModel)]="msgFilter"
                            placeholder="{{ 'SEARCH' | translate }}"
                        />
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <!-- From -->
                            <div class="form-group position-relative">
                                <label for="fromDate" translate>FROM</label>
                                <div class="input-group">
                                    <input
                                        class="form-control minw-200px"
                                        id="fromDate"
                                        name="fromDate"
                                        (click)="toggleFromPicker()"
                                        [(ngModel)]="fromDate"
                                        placeholder="{{ 'SELECT_DATE' | translate }}"
                                        (keydown)="onlyDelete($event)"
                                        autocomplete="off"
                                    />
                                    <button
                                        class="btn btn-outline-primary"
                                        (click)="clearFromDate()"
                                        type="button"
                                        title="{{ 'CLEAR_DATE' | translate }}"
                                        *ngIf="fromDate"
                                    >
                                        <fa-icon icon="times"></fa-icon>
                                    </button>
                                    <button
                                        class="btn btn-outline-primary"
                                        (click)="toggleFromPicker()"
                                        type="button"
                                        title="{{ 'SELECT_DATE' | translate }}"
                                        *ngIf="!fromDate"
                                    >
                                        <fa-icon icon="calendar-alt" size="sm"></fa-icon>
                                    </button>
                                </div>
                                <div class="picker-container absolute" *ngIf="showFromPicker" (clickOutside)="clickOutsideFromPicker()">
                                    <div class="arrow"></div>
                                    <div class="picker-content shadow-sm">
                                        <fa-icon icon="times-circle" class="close-icon" (click)="closeFromPicker()"></fa-icon>
                                        <zx-date-time-picker
                                            name="fromDatePicker"
                                            [(ngModel)]="fromDate"
                                            (ngModelChange)="fromDateChanged($event)"
                                            [onlyPastDates]="true"
                                        ></zx-date-time-picker>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- To -->
                        <div class="col-12 col-sm-6">
                            <div class="form-group position-relative">
                                <label for="toDate" translate>TO</label>
                                <div class="input-group">
                                    <input
                                        class="form-control minw-200px"
                                        id="toDate"
                                        name="toDate"
                                        (click)="toggleToPicker()"
                                        [(ngModel)]="toDate"
                                        placeholder="{{ 'SELECT_DATE' | translate }}"
                                        (keydown)="onlyDelete($event)"
                                        autocomplete="off"
                                    />
                                    <button
                                        class="btn btn-outline-primary"
                                        (click)="clearToDate()"
                                        type="button"
                                        title="{{ 'CLEAR_DATE' | translate }}"
                                        *ngIf="toDate"
                                    >
                                        <fa-icon icon="times"></fa-icon>
                                    </button>
                                    <button
                                        class="btn btn-outline-primary"
                                        (click)="toggleToPicker()"
                                        type="button"
                                        title="{{ 'SELECT_DATE' | translate }}"
                                        *ngIf="!toDate"
                                    >
                                        <fa-icon icon="calendar-alt" size="sm"></fa-icon>
                                    </button>
                                </div>
                                <div class="picker-container absolute" *ngIf="showToPicker" (clickOutside)="clickOutsideToPicker()">
                                    <div class="arrow"></div>
                                    <div class="picker-content shadow-sm">
                                        <fa-icon icon="times-circle" class="close-icon" (click)="closeToPicker()"></fa-icon>
                                        <zx-date-time-picker
                                            name="toDatePicker"
                                            [(ngModel)]="toDate"
                                            (ngModelChange)="toDateChanged($event)"
                                            [onlyPastDates]="true"
                                        ></zx-date-time-picker>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <!-- Access Tags -->
                            <div class="form-group">
                                <label for="resource_tag_ids">{{ "TAGS" | translate }}</label>
                                <zx-access-tags-select
                                    id="resource_tag_ids"
                                    name="resource_tag_ids"
                                    [formControl]="tagsControl"
                                    [(model)]="selectedResourceTags"
                                ></zx-access-tags-select>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <!-- Sources -->
                            <div class="form-group">
                                <label for="sources">{{ "SOURCES" | translate }}</label>
                                <zx-sources-select name="sources" [(model)]="selectedSources"></zx-sources-select>
                            </div>
                        </div>
                    </div>
                    <!-- Buttons -->
                    <div class="form-group">
                        <button type="button" class="btn btn-primary" (click)="reloadLogs()" title="{{ 'APPLY' | translate }}">
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "APPLY" | translate }}
                        </button>
                        <button type="button" class="btn btn-outline-secondary ms-2 me-2" (click)="resetLogs()" title="{{ 'RESET' | translate }}">
                            <fa-icon icon="undo" size="sm"></fa-icon>
                            {{ "RESET" | translate }}
                        </button>

                        <!-- Download Report -->
                        <span
                            [ngClass]="{ 'appear-disabled': !allowDownloadButton }"
                            [attr.title]="!allowDownloadButton ? ('SELECT_DATE_RANGE' | translate) : null"
                            *ngIf="isAdmin"
                        >
                            <a
                                id="quick-report"
                                class="btn btn-outline-primary"
                                [ngClass]="{ disabled: !allowDownloadButton }"
                                href="/api/scte35/report?{{ repotParameters }}"
                                translate
                            >
                                <fa-icon icon="download" size="1x" class="me-1"></fa-icon>DOWNLOAD
                            </a>
                        </span>
                    </div>
                </div>
            </form>
        </div>

        <!-- Table -->
        <div class="event-list" [hidden]="loading" #listPanel>
            <div class="list-content" #listContent>
                <div class="list-container">
                    <app-table-list
                        [data]="scteLogs$ | async"
                        [tableName]="'scte-35_log'"
                        [displayTableName]="'SCTE_35_LOGS' | translate"
                        [(tableSchema)]="tableColumnsSchema"
                        (currentPageInfo)="currentPageInfo($event)"
                        [infinitePages]="morePages"
                        [showSelectionCheckbox]="false"
                        [showFilter]="false"
                        [isColumnSelectionNeeded]="false"
                        [showReport]="false"
                        [autoRows]="false"
                        [selectable]="false"
                        [hoverable]="false"
                    >
                        <button
                            type="button"
                            class="btn btn-outline-secondary"
                            (click)="toggleLogsStyle()"
                            title="{{ isScte35ColorsVisible ? ('HIDE_COLORS' | translate) : ('SHOW_COLORS' | translate) }}"
                        >
                            <fa-icon *ngIf="!isScte35ColorsVisible" class="me-1" icon="droplet" size="sm"></fa-icon
                            ><fa-icon *ngIf="isScte35ColorsVisible" class="me-1" [icon]="['far', 'droplet']" size="sm"></fa-icon
                            >{{ isScte35ColorsVisible ? ("HIDE_COLORS" | translate) : ("SHOW_COLORS" | translate) }}
                        </button>
                    </app-table-list>
                </div>
            </div>
        </div>
        <spinner-animation *ngIf="loading" class="mt-5"></spinner-animation>
    </div>
</div>
